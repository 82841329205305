import { Section } from "@/components";
import styled from "@emotion/styled";
import React from "react";

import drawing from "./drawing-4.svg";

const Form = styled.form`
  input,
  textarea {
    border-radius: 3px;
  }
`;

const ContactUs = () => (
  <Section
    background={{
      src: drawing,
      position: "right",
      size: "50%"
    }}
  >
    <div className="uk-container uk-container-small">
      <div className="uk-h3">Get in touch</div>

      <Form
        name="contact"
        method="POST"
        action="/success/"
        className="uk-form-width-large"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />

        <div hidden>
          <label htmlFor="bot-field">
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </div>

        <div className="uk-margin">
          <input
            name="name"
            className="uk-input"
            type="text"
            placeholder="Your Name"
            required
          />
        </div>
        <div className="uk-margin">
          <input
            name="email"
            className="uk-input"
            type="email"
            placeholder="Email"
            required
          />
        </div>
        <div className="uk-margin">
          <textarea
            name="message"
            className="uk-textarea"
            rows="6"
            maxLength="2000"
            placeholder="Message"
            required
          />
        </div>
        <div className="uk-margin uk-text-right">
          <button
            type="submit"
            className="uk-button uk-button-primary uk-button-small uk-link-reset uk-border-rounded"
          >
            Submit
          </button>
        </div>
      </Form>
    </div>
  </Section>
);

export default ContactUs;
